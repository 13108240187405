const config = {
    development: {
      apiUrl: "/api",
      serverUrl: "https://localhost:44358",
      loginUrl: "https://localhost:44360/Login"
      //},
      //apiUrl: "https://localhost:5001/api",
      //serverUrl: "https://localhost:5001"
    },
    test: {
        apiUrl: "http://3.22.5.108:8080/RateCal/api",
        serverUrl: "http://3.22.5.108:8080/RateCal/"
    },
    production: {
        //https://bankinterface.ceylonexchange.com.au:8080/

        apiUrl: "https://rates.ceylonexchange.com.au/RateCal/api",
        serverUrl: "https://rates.ceylonexchange.com.au/RateCal",
      loginUrl: "https://rates.ceylonexchange.com.au/RateCal/Login"



      //  apiUrl: "http://3.22.5.108:8080/RateCal/api",
      //serverUrl: "http://3.22.5.108:8080/RateCal"
    },
    uat: {
      apiUrl: "http://18.219.149.204:80/App/api"
    }
  
  };
  
  exports.get = function get(env) {
    return config[env] || config.development;
  };
  